
import { Options, Vue } from 'vue-class-component'
import api from '@/api'
import { toDate } from '../../utils/common'
import store from '../../store'
@Options({
  components: {}
})
export default class bankruptcyInfo extends Vue {
  toDate = toDate;
  d = {};
  loading = false
  created () {
    this.loading = true
    api
      .getBankruptcyCheckDetail({
        id: this.$route.params.id,
        ...store.getters['company/get']
      })
      .then((data: any) => {
        this.d = data
      }).finally(() => {
        this.loading = false
      })
  }
}
